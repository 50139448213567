const Noodl = require('@noodl/noodl-sdk');
import './style.css'
import { AwsRum } from 'aws-rum-web';

/*
guestRoleArn: "arn:aws:iam::335954560763:role/RUM-Monitor-eu-central-1-335954560763-7122722134761-Unauth",
identityPoolId: "eu-central-1:cf716409-67db-4c30-92fe-5a4c8849fa46",
const APPLICATION_ID = 'ec940d3e-c230-46b7-94b5-04254da8bae4';
*/


// todo: must be initialized, see i18next noodl module for making sure lib is setup after noodl site is initialized

const awsRumNode = Noodl.defineNode({
    name: 'Prodikt AWS RUM component',
    category: 'Component',
    inputs:{
        guestRoleArn: 'string',
        identityPoolId: 'string',
        APPLICATION_ID: 'string',
        enabled: 'boolean'
    },
    signals: {
        initialize: function () {
            console.log('prodikt-aws-rum initialize signal',this.inputs)
            this.setupAwsRum()
        }
    },
    methods: {
        setupAwsRum: function () {
            if (this.inputs.enabled) {
                try {
                    const config = {
                        sessionSampleRate: 1,
                        guestRoleArn: this.inputs.guestRoleArn,
                        identityPoolId: this.inputs.identityPoolId,
                        endpoint: "https://dataplane.rum.eu-central-1.amazonaws.com",
                        telemetries: ["performance", "errors", "http"],
                        allowCookies: true,
                        enableXRay: false
                    };

                    const APPLICATION_ID = this.inputs.APPLICATION_ID;
                    const APPLICATION_VERSION = '1.0.0';
                    const APPLICATION_REGION = 'eu-central-1';

                    const awsRum = new AwsRum(
                        APPLICATION_ID,
                        APPLICATION_VERSION,
                        APPLICATION_REGION,
                        config
                    );
                } catch (error) {
                    console.log('prodikt-aws-rum error in aws rum setup', error)
                    // Ignore errors thrown during CloudWatch RUM web client initialization
                }
            }
        }
    }
});

Noodl.defineModule({
    nodes:[
        awsRumNode
    ],
    setup() {
    }
});